@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

nav select option {
    background: #E94B4B;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;
}

.card:hover .to-top {
    top: -100%;
}

.tabbg {
    background-image: url(img/tabbg.png);
    background-size: 100%;
}

.bg-scrolbottom {
    background-image: url(img/scrollBottom.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
}

.captie::after {
    content: "";
    width: 0vw;
    height: 0vw;
    position: absolute;
    top: 0;
    right: -5.2vw;
    border-bottom: 3vw #F8F3EC solid;
    border-left: 3vw #F8F3EC solid;
    border-top: 3vw solid transparent;
    border-right: 3vw solid transparent;
}

.dragger-scroll::-webkit-scrollbar {
    width: 1px;
}



.feather {
    position: absolute;
    width: 10vw;
    height: 10vw;
    z-index: 99;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(img/pat.png);
    animation: trans alternate-reverse infinite 34s;
    -webkit-animation: trans alternate-reverse infinite 34s;
}



.feather2 {
    position: absolute;
    width: 10vw;
    height: 10vw;
    z-index: 99;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(img/pat.png);
    animation: trans2 alternate-reverse infinite 34s;
    -webkit-animation: trans2 alternate-reverse infinite 34s;
}

.feather3 {
    position: absolute;
    width: 10vw;
    height: 10vw;
    z-index: 99;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(img/pat.png);
    animation: trans3 alternate-reverse infinite 60s;
    -webkit-animation: trans3 alternate-reverse infinite 60s;
}

@keyframes trans {
    0% {
        transform: translate(120px, 0px) rotate(10deg);
        -webkit-transform: translate(120px, 0px) rotate(10deg);
        -moz-transform: translate(120px, 0px) rotate(10deg);
        -ms-transform: translate(120px, 0px) rotate(10deg);
        -o-transform: translate(120px, 0px) rotate(10deg);
    }

    20% {
        transform: translate(0px, 20px) rotate(210deg);
        -webkit-transform: translate(0px, 20px) rotate(210deg);
        -moz-transform: translate(0px, 20px) rotate(210deg);
        -ms-transform: translate(0px, 20px) rotate(210deg);
        -o-transform: translate(0px, 20px) rotate(210deg);
    }

    40% {
        transform: translate(320px, 180px) rotate(-110deg);
        -webkit-transform: translate(320px, 180px) rotate(-110deg);
        -moz-transform: translate(320px, 180px) rotate(-110deg);
        -ms-transform: translate(320px, 180px) rotate(-110deg);
        -o-transform: translate(320px, 180px) rotate(-110deg);
    }

    60% {
        transform: translate(-220px, 470px) rotate(210deg);
        -webkit-transform: translate(-220px, 470px) rotate(210deg);
        -moz-transform: translate(-220px, 470px) rotate(210deg);
        -ms-transform: translate(-220px, 470px) rotate(210deg);
        -o-transform: translate(-220px, 470px) rotate(210deg);
    }

    80% {
        transform: translate(420px, 30px) rotate(10deg);
        -webkit-transform: translate(420px, 30px) rotate(10deg);
        -moz-transform: translate(420px, 30px) rotate(10deg);
        -ms-transform: translate(420px, 30px) rotate(10deg);
        -o-transform: translate(420px, 30px) rotate(10deg);
    }

    100% {
        transform: translate(120px, 0px) rotate(110deg);
        -webkit-transform: translate(120px, 0px) rotate(110deg);
        -moz-transform: translate(120px, 0px) rotate(110deg);
        -ms-transform: translate(120px, 0px) rotate(110deg);
        -o-transform: translate(120px, 0px) rotate(110deg);
    }
}

@keyframes trans2 {
    0% {
        transform: translate(220px, 400px) rotate(110deg);
        -webkit-transform: translate(220px, 400px) rotate(110deg);
        -moz-transform: translate(220px, 400px) rotate(110deg);
        -ms-transform: translate(220px, 400px) rotate(110deg);
        -o-transform: translate(220px, 400px) rotate(110deg);
    }

    20% {
        transform: translate(100px, 120px) rotate(10deg);
        -webkit-transform: translate(100px, 120px) rotate(10deg);
        -moz-transform: translate(100px, 120px) rotate(10deg);
        -ms-transform: translate(100px, 120px) rotate(10deg);
        -o-transform: translate(100px, 120px) rotate(10deg);
    }

    40% {
        transform: translate(420px, 880px) rotate(-210deg);
        -webkit-transform: translate(420px, 880px) rotate(-210deg);
        -moz-transform: translate(420px, 880px) rotate(-210deg);
        -ms-transform: translate(420px, 880px) rotate(-210deg);
        -o-transform: translate(420px, 880px) rotate(-210deg);
    }

    60% {
        transform: translate(120px, 470px) rotate(10deg);
        -webkit-transform: translate(120px, 470px) rotate(10deg);
        -moz-transform: translate(120px, 470px) rotate(10deg);
        -ms-transform: translate(120px, 470px) rotate(10deg);
        -o-transform: translate(120px, 470px) rotate(10deg);
    }

    80% {
        transform: translate(120px, 320px) rotate(110deg);
        -webkit-transform: translate(120px, 320px) rotate(110deg);
        -moz-transform: translate(120px, 320px) rotate(110deg);
        -ms-transform: translate(120px, 320px) rotate(110deg);
        -o-transform: translate(120px, 320px) rotate(110deg);
    }

    100% {
        transform: translate(220px, 0px) rotate(310deg);
        -webkit-transform: translate(220px, 0px) rotate(310deg);
        -moz-transform: translate(220px, 0px) rotate(310deg);
        -ms-transform: translate(220px, 0px) rotate(310deg);
        -o-transform: translate(220px, 0px) rotate(310deg);
    }
}

@keyframes trans3 {
    0% {
        transform: translate(20px, 120px) rotate(10deg);
        -webkit-transform: translate(20px, 120px) rotate(10deg);
        -moz-transform: translate(20px, 120px) rotate(10deg);
        -ms-transform: translate(20px, 120px) rotate(10deg);
        -o-transform: translate(20px, 120px) rotate(10deg);
    }

    20% {
        transform: translate(210px, 220px) rotate(210deg);
        -webkit-transform: translate(210px, 220px) rotate(210deg);
        -moz-transform: translate(210px, 220px) rotate(210deg);
        -ms-transform: translate(210px, 220px) rotate(210deg);
        -o-transform: translate(210px, 220px) rotate(210deg);
    }

    40% {
        transform: translate(690px, 210px) rotate(-110deg);
        -webkit-transform: translate(690px, 210px) rotate(-110deg);
        -moz-transform: translate(690px, 210px) rotate(-110deg);
        -ms-transform: translate(690px, 210px) rotate(-110deg);
        -o-transform: translate(690px, 210px) rotate(-110deg);
    }

    60% {
        transform: translate(120px, 170px) rotate(210deg);
        -webkit-transform: translate(120px, 170px) rotate(210deg);
        -moz-transform: translate(120px, 170px) rotate(210deg);
        -ms-transform: translate(120px, 170px) rotate(210deg);
        -o-transform: translate(120px, 170px) rotate(210deg);
    }

    80% {
        transform: translate(320px, 630px) rotate(10deg);
        -webkit-transform: translate(320px, 630px) rotate(10deg);
        -moz-transform: translate(320px, 630px) rotate(10deg);
        -ms-transform: translate(320px, 630px) rotate(10deg);
        -o-transform: translate(320px, 630px) rotate(10deg);
    }

    100% {
        transform: translate(20px, 400px) rotate(110deg);
        -webkit-transform: translate(20px, 400px) rotate(110deg);
        -moz-transform: translate(20px, 400px) rotate(110deg);
        -ms-transform: translate(20px, 400px) rotate(110deg);
        -o-transform: translate(20px, 400px) rotate(110deg);
    }
}



.bg-phone {
    background: transparent;
    background-image: url(img/phone.png)
}

.hoverer .opacity-hover {
    opacity: 0;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.hoverer:hover .opacity-hover {
    opacity: 1;
}

.notifbg {
    background-image: url(img/notifbg.png);
    background-size: 100%;
    animation: notifbg .2s both;
    -webkit-animation: notifbg .2s both;
}

.notifbg2 {
    background-image: url(img/notifbg2.png);
    background-size: 100%;
    animation: notifbg2 1s both;
    -webkit-animation: notifbg2 1s both;
}

.modal-income {
    animation: notifbg .2s both;
    -webkit-animation: notifbg .2s both;
}

@keyframes notifbg {
    from {
        transform: scale(.1);
        -webkit-transform: scale(.1);
        -moz-transform: scale(.1);
        -ms-transform: scale(.1);
        -o-transform: scale(.1);
    }

    to {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@keyframes notifbg2 {
    0% {
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }

    90% {
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }

    99% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }

    100% {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }
}

.two:hover~.after-bg::after {
    left: 25%;
}

.three:hover~.after-bg::after {
    left: 50%;
}

.four:hover~.after-bg::after {
    left: 75% !important;
}

.left-25::after {
    left: 25% !important;
}

.left-50::after {
    left: 50% !important;
}

.left-75::after {
    left: 75% !important;
}

.after-bg::after {
    top: -2vw;
    width: 10vw;
    height: 20vw;
    background: rgba(255, 255, 255, 0.158);
    position: absolute;
    content: '';
    left: 0%;
    z-index: -1;
    border-radius: 10vw;
    -webkit-border-radius: 10vw;
    -moz-border-radius: 10vw;
    -ms-border-radius: 10vw;
    -o-border-radius: 10vw;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

#blackdoor {
    transition: 2s;
    -webkit-transition: 2s;
    -moz-transition: 2s;
    -ms-transition: 2s;
    -o-transition: 2s;
}

@media(max-width:768px) {

    .feather3,
    .feather2,
    .feather {
        display: none;
    }

    .notifbg2 {
        transform: scale(3.5) !important;
        -webkit-transform: scale(3.5) !important;
        -moz-transform: scale(3.5) !important;
        -ms-transform: scale(3.5) !important;
        -o-transform: scale(3.5) !important;
    }
}

@media(min-width:768px) {
    .custom-slick .slick-arrow {
        bottom: -3vw !important;
        position: absolute;
        top: auto;
        transform: scale(2.3);
        -webkit-transform: scale(2.3);
        -moz-transform: scale(2.3);
        -ms-transform: scale(2.3);
        -o-transform: scale(2.3);
    }

    .custom-slick .slick-arrow.slick-next {
        right: 5vw;
    }

    .custom-slick .slick-arrow.slick-prev {
        right: 10vw;
        left: auto;

    }

    .linear-bg {
        background-image: url(img/bgproduct.png);
        background-size: 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        background-color: #ff4a4a;
    }

    .hover-on {
        cursor: pointer;
    }

    .hover-on p {
        opacity: 0;
        transition: .3s;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;
    }

    .hover-on:hover p {
        opacity: 1
    }
}

.loader {
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 999;
    background-image: url(img/loader.png);
    background-size: cover;
    background-position: center
}

.loading {
    width: 20vw;
    height: 1vw;
    background-color: #ff44337e;
    border-radius: 3vw;
    -webkit-border-radius: 3vw;
    -moz-border-radius: 3vw;
    -ms-border-radius: 3vw;
    -o-border-radius: 3vw;
    position: relative;
    left: 40vw;
    top: 80vh;
    overflow: hidden;
}

.loading::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    background: #FF4333;
    content: "";
    height: 100%;
    animation: animationLoading 2.8s ease-in-out both infinite;
    -webkit-animation: animationLoading 2.8s ease-in-out both infinite;
}

@keyframes animationLoading {
    0% {
        left: 0;
    }

    50% {
        left: 80%;
    }

    100% {
        left: 0;
    }
}

.bg-slide {
    background-image: url(img/bgslide.png);
    background-size: cover;
}

@tailwind base;
@tailwind components;
@tailwind utilities;